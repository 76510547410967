<template>
  <EventManagementPage
    class="print"
    title="Ticket Printing"
    :breadcrumbs="breadcrumbs"
    @load="onLoad"
  >
    <processing-modal :active="processing" v-on:cancel="processing = false" />
    <div class="row">
      <div class="col full mb1">
        <h2>Printing Directions</h2>
        <p class="print-info">
          You can print straight from your ticket tier's inventory on either
          letter or stock paper. All tickets come with a unique bar code that
          can only be scanned once. DO NOT print multiple copies of these
          tickets. It will only confuse your ticket buyers, and create headaches
          for you on the date of your event.
        </p>
        <p class="print-info">
          If you are printing a large quantity of tickets (50+) it may take some
          time for your browser to download the file. Please be patient while
          the download takes place.
        </p>
        <!-- <div class="divider"></div> -->
        <p class="print-info">
          TO PRINT STOCK: You must buy a BOCA
          <a href="https://www.bocasystems.com/lemurprinters.html">printer</a>
          and
          <a href="https://www.bocasystems.com/tickets.html">ticket stock</a>
          . We no longer provide these items as you must be a direct consumer of
          BOCA to receive their excellent support. You may have to rotate the
          ticket images to print. It really depends on what app you use to view
          the prints and what printer you have.
        </p>
      </div>
      <div class="col full mb2">
        <!-- All info -->
        <div class="col half">
          <diyobo-input
            class="Name"
            type="text"
            label="Name of Ticket Holder"
            placeholder="Name of Ticket Holder"
            :required="true"
            v-model="nameOfTicketHolder"
            :error="error('nameOfTicketHolder')"
            :val="nameOfTicketHolder"
          />
        </div>

        <div class="col half">
          <diyobo-input
            class="Email"
            type="text"
            label="Email of Ticket Holder"
            placeholder="Email of Ticket Holder"
            :required="true"
            v-model="emailOfTicketHolder"
            :error="error('emailOfTicketHolder')"
            :val="emailOfTicketHolder"
          />
        </div>

        <div class="col half">
          <TicketTierSelector
            :tiers="oldEvent.tiers"
            :error="error('tierInput')"
            v-model="tier"
          />
        </div>

        <!-- <div class="col half">
      <div class="image-uploader-input">
        <image-uploader
          label="Upload Image"
          :info="{}"
          :indexToInject="1"
          :bus="bus3"
          v-on:data="uploadImageData('ticketLogo', ...arguments)"
        />
      </div>
    </div> -->
        <!-- End All Info -->

        <div class="col half">
          <diyobo-input
            class="PDF"
            label="Select PDF Type"
            type="dropdown"
            placeholder="No PDF Selected"
            :options="pdfTypes"
            :required="true"
            v-model="pdfInput"
            :error="error('pdfInput')"
            :val="pdfInput"
            :bus="bus2"
          />
        </div>

        <!-- General Tier Info -->
        <div class="col half">
          <div class="number-wrapper" v-if="tier && !tier.invalid">
            <diyobo-input
              class="num-tix"
              type="number"
              label="Number of Tickets to Print"
              placeholder="Number of Tickets to Print"
              :required="true"
              v-model="numberOfTicketsInput"
              :error="error('numberOfTicketsInput')"
              :val="numberOfTicketsInput"
              :bus="bus4"
            />
            <div class="quantity-number">Max Quantity: {{ maxQuantity }}</div>
          </div>
        </div>
        <!-- End General Tier info -->

        <!-- All INFO -->
      </div>
      <div class="button-container">
        <diyobo-button
          type="primary-dark"
          txt="Back To My Events"
          @click="toEvents"
        />

        <diyobo-button
          type="primary"
          txt="Create"
          v-on:click="SubmitRequest()"
        />
      </div>
    </div>
    <div class="divider"></div>
    <section>
      <div class="row">
        <div class="col full">
          <table-tote v-model="tableData" />
          <div class="img-cont">
            <img
              id="img"
              src="../assets/IE_InPerson-NoTerms_8x10_Ticket.jpg"
              alt
            />
            <img id="img-ticket-stock" src="../assets/stockTicket.jpg" alt />
            <img id="img-1" src="/img/logos/TicketTote-Logo-Black.png" alt />
            <img id="img-2" src="/img/logos/TicketTote-Logo-Black.png" alt />
            <canvas id="canvas" style="display: none"></canvas>
            <canvas
              id="icon-canvas"
              width="300"
              height="300"
              style="display: none"
            ></canvas>
          </div>
        </div>
      </div>
    </section>

    <PDFViewer
      v-if="pdfData"
      :src="pdfData"
      :file-name="pdfFileName"
      :numPages="pdfPages"
      @close="pdfData = null"
      :invoice="true"
    />
  </EventManagementPage>
</template>

<script>
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import ImageUploader from "../components/imageUploaders/TTButtonImageUploader.vue";
import ModalContent from "@/helpers/modals";
import TableTote from "@/components/TableTote.vue";
import ProcessingModal from "@/components/modals/ProcessingModal.vue";

import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFilePdf, faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import ErrorList from "../components/ErrorList.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dateFormat from "dateformat";
import { viewTicket, viewTicketStock } from "@/helpers/tickets.js";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import PDFViewer from "@/components/PDFViewer.vue";
import EventManagementPage from "../components/EventManagementPage";
import TicketTierSelector from "@/components/TicketTierSelector.vue";
import {
  getSaleDate,
  getVenueAccessDate,
  getVenueAccessTime
} from "../../../shared/helpers/tickets";
import dayjs from "dayjs";

library.add(faFilePdf);
library.add(faTicketAlt);

export default {
  name: "print",
  components: {
    EventManagementPage,
    DiyoboInput,
    DiyoboButton,
    TableTote,
    FontAwesomeIcon,
    ErrorList,
    ImageUploader,
    Breadcrumbs,
    PDFViewer,
    ProcessingModal,
    TicketTierSelector
  },
  head() {
    return {
      title: "Print Tickets"
    };
  },
  data() {
    return {
      processing: false,
      breadcrumbs: [
        ["Dashboard", "/dashboard"],
        ["Print Tickets", null]
      ],
      saleDateArr: [],
      tiers: [],
      pdfTypes: [
        { label: 'Letter (8.5" x 11")', value: 'Letter (8.5" x 11")' },
        { label: "Stock", value: "Stock" }
      ],
      printedTickets: [],
      tabelSize: [
        { label: "10", value: 10 },
        { label: "25", value: 25 },
        { label: "50", value: 50 },
        { label: "100", value: 100 }
      ],
      paginationArray: [],
      errors: [
        {
          name: "tierInput",
          display: "Tier Input",
          error: false,
          required: true
        },
        {
          name: "dateInputID",
          display: "Date Input",
          error: false,
          required: false
        },
        {
          name: "numberOfTicketsInput",
          display: "Number Of Tickets",
          error: false,
          required: true
        },
        {
          name: "nameOfTicketHolder",
          display: "Name Of Ticket",
          error: false,
          required: true
        },
        {
          name: "emailOfTicketHolder",
          display: "Email Of Ticket",
          error: false,
          required: true
        },
        {
          name: "pdfInput",
          display: "PDF Input",
          error: false,
          required: true
        },
        {
          name: "tierDate",
          display: "Tier Date",
          error: false,
          required: false
        }
      ],
      ticketQuantity: [],
      theUrl: process.env.VUE_APP_SERVICE_URL,
      searchBar: "",
      ticketLogo: "",
      nameOfTicketHolder: "",
      emailOfTicketHolder: "",
      updateEntry: "",
      numberOfTicketsInput: "",
      tierInput: "",
      dateInputID: "",
      entryDateId: "",
      saleDateRefArray: [],
      tier: null,
      pdfInput: "",
      ticketsError: false,
      tierError: false,
      dateError: false,
      numberOfTicketsError: false,
      pdfError: false,
      errorMessages: [],
      errorsToCorrect: [],
      eventUrl: this.$route.params.url,
      bus1: new Vue(),
      bus2: new Vue(),
      bus3: new Vue(),
      bus4: new Vue(),
      bus5: new Vue(),
      tableLength: false,
      tableSizeVal: 10,
      pagIsTrue: false,
      oldEvent: {},
      tableData: {},
      maxQuantity: 0,
      tierType: "",
      tierDateArr: [],
      tierDate: "",
      selectedTier: {},
      fee: 0,
      pdfData: null,
      pdfPages: 1,
      pdfFileName: ""
    };
  },
  watch: {
    tier: {
      handler(values) {
        // console.log(values);

        if (!values) {
          return;
        }

        this.changeQuantity();
        this.count = "";
        this.reason = "";
        this.pdf = "Letter";
        this.imgType = "New Upload";
        this.bus5.$emit("replace-placeholder", "Select Sale Date");
      },
      deep: true
    }
  },
  methods: {
    toEvents() {
      this.$router.push("/my-events");
    },
    error(input) {
      let error = this.errors.find(e => e.name === input);
      return error && error.error;
    },
    validate() {
      let self = this;

      this.errors.forEach(ele => {
        ele.errors = [];
        if (ele.name === "tierInput") {
          ele.error = !this.tier || this.tier.invalid;
        } else if (!this[ele.name]) {
          if (ele.required == false) {
            ele.error = false;
          } else {
            ele.error = true;
          }
        } else if (ele.name == "numberOfTicketsInput") {
          if (parseInt(self.numberOfTicketsInput) > self.maxQuantity) {
            ele.error = true;
          } else {
            ele.error = false;
          }
        } else {
          ele.error = false;
        }
        if (ele.error) {
          if (ele.name == "numberOfTicketsInput") {
            ele.errors.push(
              `${ele.display} Cannot Be Greater Then The Max Quantitiy`
            );
          } else {
            ele.errors.push(`${ele.display} is Required`);
          }
        }
      });
      return !this.errors.some(e => e.error);
    },
    changeQuantity() {
      this.ticketQuantity = [];
      let self = this;
      const tiers = this.oldEvent.tiers;

      if (!this.tier) {
        self.maxQuantity = 0;
      }

      if (this.tier.type === "General Tier") {
        const saleDate = getSaleDate(tiers, this.tier.saleDateID);
        if (saleDate) {
          let tierQuantity = saleDate.qty;
          for (
            let y = tierQuantity - saleDate.start + 1;
            y < tierQuantity - saleDate.start + 2;
            y++
          ) {
            self.maxQuantity = y;
          }
        }
      } else if (this.tier.type === "Date") {
        const venueAccessDate = getVenueAccessDate(
          tiers,
          this.tier.venueAccessDateID
        );

        if (venueAccessDate) {
          self.maxQuantity = venueAccessDate.available;
        }
      } else if (this.tier.type === "Date and Time") {
        const venueAccessTime = getVenueAccessTime(
          tiers,
          this.tier.venueAccessDateID,
          this.tier.venueAccessTimeID
        );

        // console.log(venueAccessTime);

        if (venueAccessTime) {
          self.maxQuantity = venueAccessTime.available;
        }
      }
    },
    viewTickets(ticket) {
      let self = this;
      let address = self.oldEvent.venueAddress;
      let canvas = document.getElementById("canvas");
      let canvas1 = document.getElementById("icon-canvas");
      this.pdfPages = ticket.ticket_count;
      this.processing = true;

      viewTicket(
        ticket,
        self.oldEvent,
        canvas,
        canvas1,
        address,
        true,
        false,
        tickets => {
          this.pdfData = tickets.pdf;
          this.pdfFileName = tickets.fileName;
          this.processing = false;
        }
      );
    },
    viewTicketStock(value) {
      let self = this;
      let canvas = document.getElementById("canvas");
      let canvas1 = document.getElementById("icon-canvas");
      this.pdfPages = value.ticket_count;
      this.processing = true;

      viewTicketStock(value, self.oldEvent, canvas, canvas1, tickets => {
        this.pdfData = tickets.pdf;
        this.pdfFileName = tickets.fileName;
        this.processing = false;
      });
    },
    SubmitRequest() {
      let self = this;
      if (self.validate()) {
        window.scrollTo(0, 0);
        let arrayOfNumbers = [];
        let tierType;

        let startNumber;
        let endNumber;
        let tierDate;
        let tiers = self.oldEvent.tiers;

        const elem = tiers.find(t => t.id === this.tier.tierID);

        if (elem.type === "General Tier") {
          const saleDate = getSaleDate(elem, this.tier.saleDateID);
          startNumber = saleDate.start;
          endNumber =
            parseInt(startNumber) + parseInt(self.numberOfTicketsInput);
          saleDate.start += parseInt(self.numberOfTicketsInput);
          tierDate = dayjs(
            +saleDate.sale_start.$date.$numberLong
          ).toISOString();
        } else if (elem.type === "Date") {
          const venueAccessDate = getVenueAccessDate(
            elem,
            this.tier.venueAccessDateID
          );
          startNumber = venueAccessDate.sold + elem.tixStartNum;
          endNumber =
            parseInt(startNumber) + parseInt(self.numberOfTicketsInput);
          venueAccessDate.sold += parseInt(self.numberOfTicketsInput);
          venueAccessDate.available -= parseInt(self.numberOfTicketsInput);
          tierDate = dayjs(
            +venueAccessDate.start.$date.$numberLong
          ).toISOString();
        } else if (elem.type === "Date and Time") {
          const venueAccessTime = getVenueAccessTime(
            elem,
            this.tier.venueAccessDateID,
            this.tier.venueAccessTimeID
          );
          startNumber = venueAccessTime.sold + elem.tixStartNum;
          endNumber =
            parseInt(startNumber) + parseInt(self.numberOfTicketsInput);
          venueAccessTime.sold += parseInt(self.numberOfTicketsInput);
          venueAccessTime.available -= parseInt(self.numberOfTicketsInput);
          tierDate = dayjs(
            +venueAccessTime.start.$date.$numberLong
          ).toISOString();
        }

        for (let t = startNumber; t < endNumber; t++) {
          arrayOfNumbers.push(t);
        }

        let ticket = {
          name: self.nameOfTicketHolder,
          email: self.emailOfTicketHolder,
          entryDateID: "test val",
          tier: elem.name,
          tier_id: elem.id,
          tickets: self.numberOfTicketsInput,
          type: self.pdfInput,
          tierType: tierType,
          icon: self.ticketLogo,
          url: self.eventUrl,
          event_id: self.oldEvent._id,
          ticketNumbers: arrayOfNumbers,
          ticketType: self.tier.type,
          saleDateID: self.tier.saleDateID,
          venueAccessDateID: self.tier.venueAccessDateID,
          venueAccessTimeID: self.tier.venueAccessTimeID,
          fee: self.fee,
          date: this.eventDateID,
          tierDate
        };

        // send as FormData to properly handle image uploads
        const data = new FormData();

        data.append("data", JSON.stringify(ticket));

        if (self.ticketLogo) {
          data.append("icon", self.ticketLogo);
        }

        this.$axios.post(`/planners/print-tickets`, data).then(response => {
          if (response.data[0].creationDate) {
            response.data.creationDate = dateFormat(
              +response.data.creationAt,
              "ddd, mmm dS, yyyy"
            );
          }

          response.data.ticket_date = "";
          response.data.ticket_time = "";

          // if (response.data.tierDate && response.data.tierDate.$date) {
          //   if (
          //     response.data.tierType === "Date" ||
          //     response.data.tierType === "Date and Time"
          //   ) {
          //     response.data.ticket_date = dayjs(
          //       +response.data.tierDate.$date.$numberLong
          //     ).format("MMM DD, YYYY");
          //   }

          //   if (response.data.tierType === "Date and Time") {
          //     response.data.ticket_time = dayjs(
          //       +ticket.tierDate.$date.$numberLong
          //     ).format("h:mm A");
          //   }
          // }

          self.printedTickets.push(response.data[0]);
          self.tableData.data = self.printedTickets;

          self.tier = null;
          self.nameOfTicketHolder = "";
          self.emailOfTicketHolder = "";
          self.numberOfTicketsInput = "";
          self.tierInput = "";
          self.dateInputID = "";
          self.pdfInput = "";
          self.ticketLogo = "";

          self.bus2.$emit("replace-placeholder", "Select PDF type");
          self.bus1.$emit("replace-placeholder", "Select Ticket Tier");
          self.bus3.$emit("delete-img", "");

          //This is where we activate the alert modal
          self.$store.state.bus.$emit(
            "alert",
            ModalContent.printTicketsSuccess
          );
        });
      } else {
        window.scrollTo(0, 0);
      }
    },
    uploadImageData(key, value) {
      //value contains an object that has two props, image; imageName.
      this[key] = value.imageFile;
    },
    onLoad({ dateID }) {
      let self = this;
      this.eventDateID = dateID;

      self.tier = null;
      self.nameOfTicketHolder = "";
      self.emailOfTicketHolder = "";
      self.numberOfTicketsInput = "";
      self.tierInput = "";
      self.dateInputID = "";
      self.pdfInput = "";
      self.ticketLogo = "";

      self.bus2.$emit("replace-placeholder", "Select PDF type");
      self.bus1.$emit("replace-placeholder", "Select Ticket Tier");
      self.bus3.$emit("delete-img", "");

      this.$axios
        .post(`/planners/get-print-info`, {
          eventUrl: this.eventUrl,
          date: dateID
        })
        .then(resp => {
          let data = resp.data;
          // console.log(data);
          this.oldEvent = data.Event;
          self.printedTickets = [];

          for (let x = 0; x < data.Tickets.length; x++) {
            const ticket = data.Tickets[x];
            if (ticket.creationDate && ticket.creationDate.$date) {
              ticket.creationDate = dateFormat(
                +ticket.creationDate.$date.$numberLong,
                "ddd, mmm dS, yyyy"
              );
            }

            ticket.ticket_date = "";
            ticket.ticket_time = "";

            if (ticket.tierDate && ticket.tierDate.$date) {
              if (
                ticket.tierType === "Date" ||
                ticket.tierType === "Date and Time"
              ) {
                ticket.ticket_date = dayjs(
                  +ticket.tierDate.$date.$numberLong
                ).format("MMM DD, YYYY");
              }

              if (ticket.tierType === "Date and Time") {
                ticket.ticket_time = dayjs(
                  +ticket.tierDate.$date.$numberLong
                ).format("h:mm A");
              }
            }

            self.printedTickets.push(ticket);
          }

          let realData = data.Event;
          for (let x = 0; x < realData.tiers.length; x++) {
            self.tiers.push({
              label: realData.tiers[x].name,
              value: realData.tiers[x].name
            });
          }

          const tickets = self.printedTickets;

          const hasNoTierDates = tickets.every(t => !t.ticket_date);
          const hasNoTierTimes = tickets.every(t => !t.ticket_time);

          this.tableData = {
            title: "Printed Tickets",
            columns: [
              { title: "Full Name", field: "name", width: "20%" },
              {
                title: "Ticket Tier",
                field: "tier",
                width: hasNoTierDates && hasNoTierTimes ? "39%" : "19%"
              },
              {
                title: "Tier Date",
                field: "ticket_date",
                width: hasNoTierTimes ? "20%" : "10%",
                hidden: hasNoTierDates
              },
              {
                title: "Tier Time",
                field: "ticket_time",
                width: "10%",
                hidden: hasNoTierTimes
              },
              { title: "# of Tix", field: "ticket_count", width: "11%" },
              {
                title: "Creation Date",
                field: "creationAt",
                width: "16%",
                date: true
              },
              { title: "Type", field: "print_type", width: "17%" },
              { title: "PDF", field: "print_type", width: "5%", icon: true }
            ],
            data: tickets,
            searchable: ["name", "tier", "creationAt", "type"],
            callbacks: {
              print_type: (row, column) => {
                if (row.print_type === "Stock") {
                  self.viewTicketStock(row);
                } else {
                  self.viewTickets(row);
                }
              }
            },
            icons: {
              print_type: (row, column, library) => {
                if (row.print_type === 'Letter (8.5" x 11")') {
                  library.add(faFilePdf);
                  return "file-pdf";
                } else if (row.print_type === "Stock") {
                  library.add(faTicketAlt);
                  return "ticket-alt";
                }
              }
            }
          };

          this.fee = parseFloat(data.fee.$numberDecimal);
        });
    }
  }
};
</script>
<style lang="less" scoped>
::-webkit-scrollbar {
  display: none;
}
.img-cont {
  display: none;
}
.edit-icon {
  margin-left: 1px;
  margin-top: 10px;
  cursor: pointer;
}
.ticket-css {
  display: block;
  right: 0;
  padding: 9px 0;
  width: 75px;
  font-size: 10px;
  font-weight: 300;
  line-height: 100%;
  color: #ffffff;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px #222222b4;
  background-color: #4eb95e;
  background-image: linear-gradient(#4eb95e, #3ba847);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  height: 25px;
  margin-top: 8px;
}
.router-link {
  margin: 0.25em;
  padding: 8px 18px;
  position: relative;
  z-index: 1;
  border: 1px solid;
  border-radius: 5px;
  outline: none;
  font-size: 1em;

  font-weight: 500;
  cursor: pointer;
  background-color: #40578d;
  background: linear-gradient(#40578d, #2f4265);
  color: #fff;
  border-color: #5c7ab8;
}
.print {
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 0 -0.25em;
    padding-top: 1em;
  }
  #Content {
    .content-inner {
      padding-bottom: 0;

      .print-info {
        font-size: 14px;
        margin: 7px 0;

        strong {
          color: var(--headers);
        }
      }

      .row {
        .mb1 {
          display: flex;
          flex-wrap: wrap;
          // justify-content: center;
          // width: 50%;

          margin: 0px auto 50px;

          h2 {
            width: 100%;
            text-align: center;
          }
        }

        .mb2 {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }

      .image-uploader-input {
        margin-bottom: 1.5em;
      }

      .number-wrapper {
        display: flex;
        justify-content: space-between;
        .num-tix {
          width: 75%;
        }
        .quantity-number {
          margin-top: 32px;
          margin-left: 10px;
        }
      }

      .divider {
        margin-top: 1em;
        margin-bottom: 1em;
      }

      section {
        margin-bottom: 0;
      }

      // end divider

      .button-container {
        display: flex;
        flex-wrap: wrap;
        margin: 20px 0;
        width: 100%;
        justify-content: center;

        button:first-child {
          margin-right: 25px;
          // margin: 0 10px;
        }
      }

      .table-wrapper {
        position: relative;
        background-color: #1e1e1e;
        border: solid 1px black;
        border-radius: 8px;
        box-shadow: inset 0 0 5px 2px #000000b9;
        width: 100%;
        overflow: auto;
        min-height: 180px;
        max-height: 271px;

        .entries {
          z-index: 10;
        }

        .entries-input {
          position: absolute;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          top: 40px;
          left: 10px;

          p {
            margin: 0px;
            padding: 0px;
          }

          .entries-dropdown {
            width: 100px;
            padding-top: 15px;
            margin: 0px 5px;
          }
        }

        .search-input {
          position: absolute;
          display: flex;
          right: 5px;
          top: 40px;
          width: 200px;
          height: 25px;
          z-index: 5;
        }

        .table-header {
          position: absolute;
          left: 10px;
        }
        // end table-header

        .table-row {
          position: relative;
          display: flex;
          padding: 0px 0px 0px 10px;

          .th-element {
            color: #4eb95e;
            font-size: 12px;
            margin: 10px 0px;
            padding: 90px 0px 5px 0px;
            border-bottom: rgba(91, 91, 91, 0.6) solid 1px;
          }
          // end of th-element

          .td-element {
            font-size: 12px;
            color: #999999;
            margin: 5px 0px;
            padding-bottom: 5px;
            border-bottom: rgba(91, 91, 91, 0.3) solid 1px;
            text-align: left;
          }
          // end of td-element

          .icon {
            position: absolute;
            top: 7px;
            width: 10px;
            height: 10px;
            margin-left: 5px;
            color: #999999;
          }
          // end icon
          .name {
            flex-basis: 24%;
          }
          .tier {
            flex-basis: 21%;
          }
          .tickets {
            flex-basis: 21%;
          }
          .creation-date {
            flex-basis: 21%;
          }

          .type {
            flex-basis: 7%;
          }

          .file-icon {
            flex-basis: 5%;
          }
        }
        // end of table-row
      }
      .table-footer {
        position: relative;
        height: 50px;

        .entries-wrapper {
          position: absolute;
          top: -7px;
          left: 1px;
        }
        .pagination {
          position: absolute;
          display: flex;
          flex-wrap: wrap;
          top: -7px;
          right: 1px;
          p {
            padding: 7px 14px;
            border: rgba(91, 91, 91, 0.3) solid 1px;
            // color: #4eb95e;
          }
          p.active {
            background-color: rgba(91, 91, 91, 0.6);
          }
          p:hover {
            cursor: pointer;
            background-color: rgba(91, 91, 91, 0.3);
          }
        }
      }
    }
  }
}
</style>
